.lookup-container {
  row-gap: 1.1rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  .form-image {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .radio-button {
      gap: 4px;
      justify-items: s;
      .radio-input {
        margin-top: 3px;
      }
    }
    .label-image {
      margin-bottom: 4px;
    }
  }
  .form-label {
    margin-bottom: 0px;
    font-size: 13px;
  }
}

.form-image img {
  cursor: pointer;
}
