.condition-group-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}
