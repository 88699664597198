.task-rule-form {
  padding: 10px;

  .date-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1440px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1280px) {
      grid-template-columns: 1fr;
    }
  }

  .condition-group,
  .action-group,
  .exception-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    input {
      flex: 1;
    }
  }
}
